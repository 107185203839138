<template>
  <!-- 编辑商品页面 -->
  <div class="container">
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <p style="font-size: 20px; font-weight: 600">编辑分区商品</p>
          <!-- tab页 -->
          <a-tabs v-model="activeKey" @change="tabCallback">
            <a-tab-pane key="1" tab="商品信息"></a-tab-pane>
            <a-tab-pane key="2" tab="商品详情" force-render></a-tab-pane>
          </a-tabs>
          <!-- 商品信息页form表单 -->
          <div v-show="activeKey == '1'">
            <a-form :form="form">
              <!-- 商品名称 -->
              <a-form-item label="商品名称" :labelCol="labelCol" :wrapperCol="wrapperCol" allow-clear>
                <a-input
                  placeholder="请输入商品名称"
                  v-decorator="['name', { rules: [{ required: true, message: '请输入商品名称!' }] }]"
                />
              </a-form-item>
              <!-- 商品分类 -->
              <a-form-item label="商品分类" :labelCol="labelCol" :wrapperCol="wrapperCol" allow-clear>
                <a-tree-select
                  style="width: 100%"
                  @change="changeSelectCategory"
                  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                  placeholder="请选择"
                  allow-clear
                  tree-default-expand-all
                  v-decorator="['goodsCategoryId', { rules: [{ required: true, message: '请选择!' }] }]"
                >
                  <a-tree-select-node
                    v-for="parentItem in classList"
                    :key="parentItem.id"
                    :value="parentItem.id"
                    :title="parentItem.name"
                    :selectable="false"
                  >
                    <a-tree-select-node
                      v-for="item in parentItem.childrenList"
                      :key="item.id"
                      :selectable="true"
                      :value="item.id"
                      :title="item.name"
                    />
                  </a-tree-select-node>
                </a-tree-select>
              </a-form-item>
              <a-form-item label="商品市场价" :labelCol="labelCol" :wrapperCol="wrapperCol" allow-clear>
                <a-input-number
                  placeholder="请输入商品市场价"
                  :min="0"
                  :max="9999999"
                  :precision="2"
                  style="width: 300px"
                  v-decorator="['marketPrice', { rules: [{ required: true, message: '请输入商品市场价!' }] }]"
                />
              </a-form-item>
              <!-- 商品关键字 -->
              <a-form-item label="商品关键字" :labelCol="labelCol" :wrapperCol="wrapperCol" allow-clear>
                <a-input placeholder="输入商品关键字" v-decorator="['keywords']" />
              </a-form-item>
              <!-- <a-form-item label="商品简介" :labelCol="labelCol" :wrapperCol="wrapperCol" allow-clear>
                          <a-textarea
                            placeholder="输入商品简介"
                            v-decorator="['productDescription']"
                            :rows="6"
                          />
                        </a-form-item> -->
              <a-form-item label="分区黑名单" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select mode="multiple" v-decorator="['blacklistAreaIds']" allow-clear placeholder="请选择分区黑名单">
                  <a-select-option v-for="(item, index) in areas" :key="index" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="分区排序" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-input-number
                  placeholder="请输入"
                  :min="1"
                  :precision="0"
                  :max="10000000"
                  style="width: 40%; min-width: 100px"
                  v-decorator="[
                    'partitionSort',
                    { rules: [{ required: true, message: '请输入分区排序！' }], initialValue: 10 },
                  ]"
                />
              </a-form-item>
              <!-- 商品封面图 -->
              <a-form-item label="商品封面图" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <div class="prcture">
                  <a-upload
                    name="avatar"
                    accept=".jpg,.png,.jpeg,.gif"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customPictureRequest"
                    v-decorator="['coverImage', { rules: [{ required: true, message: '请上传！' }] }]"
                  >
                    <img :src="picture" v-if="picture" style="width: 100px; height: 100px" />
                    <div v-else>
                      <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
                    </div>
                  </a-upload>
                </div>
              </a-form-item>
              <!-- 22-{{form.getFieldValue("tempList")}} -->
              <!-- 商品轮播图（最多10张） -->
              <a-form-item label="轮播视频" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <div style="display: flex">
                  <video
                    :src="videoSrc"
                    controls="controls"
                    v-if="videoSrc"
                    style="width: 80px; height: 80px; margin-right: 18px"
                  />
                  <a-upload
                    name="avatar"
                    accept=".mp4,.webm,.ogg"
                    list-type="picture-card"
                    class="avatar-uploader"
                    :show-upload-list="false"
                    :customRequest="customVideoRequest"
                  >
                    <a-icon :type="pictureLoading ? 'loading' : 'plus'" />
                    <div style="color: #999" class="ant-upload-text">上传视频</div>
                    <view></view>
                  </a-upload>
                </div>
              </a-form-item>
              <a-form-item label="商品轮播图" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <div class="flex">
                  <a-upload
                    list-type="picture-card"
                    :file-list="fileList"
                    multiple
                    @preview="handlePreview"
                    accept=".jpg,.png,.jpeg,.gif"
                    @change="handleChange"
                    :customRequest="showFigureRequest"
                    v-decorator="['tempList', { rules: [{ required: true, message: '请上传！' }] }]"
                  >
                    <div v-if="fileList.length < 9">
                      <a-icon type="plus" />
                      <div style="color: #999" class="ant-upload-text">最多上传9张</div>
                    </div>
                  </a-upload>
                  <a-modal :visible="previewVisible1" :footer="null" @cancel="handleCancel1">
                    <img alt="example" style="width: 100%; height: 30%" :src="previewImage" />
                  </a-modal>
                </div>
              </a-form-item>
              <!-- 退货政策 -->
              <!-- <a-form-item label="退货政策" :labelCol="labelCol" :wrapperCol="wrapperCol">
								<div class="flex">
									<a-radio-group name="radioGroup"
										v-decorator="['returnGoodsPolicy', { rules: [{ required: true, message: '请选择！' }] }]">
										<a-radio :value="1">七天退货</a-radio>
										<a-radio :value="2">不退货</a-radio>
									</a-radio-group>
								</div>
							</a-form-item> -->
              <!-- 商品规格 -->
              <a-form-item label="商品规格" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-radio-group name="radioGroup" v-model="speType">
                  <a-radio :value="1">单规格</a-radio>
                  <a-radio :value="2">多规格</a-radio>
                </a-radio-group>
                <div v-if="speType == 1">
                  <a-table :columns="columns" :data-source="data" :pagination="false" rowKey="id" bordered>
                    <span class="prcture" slot="tupian" slot-scope="text, record, index">
                      <div @click="chooseSpecIndex(index)">
                        <a-upload
                          name="avatar"
                          accept=".jpg,.png,.jpeg,.gif"
                          list-type="picture-card"
                          class="avatar-uploader"
                          :show-upload-list="false"
                          :customRequest="customSpecRequest"
                          v-decorator="['spec', { rules: [{ required: true, message: '请上传图片！' }] }]"
                        >
                          <img class="clearfix" v-if="record.image" :src="record.image" />
                          <div v-else>
                            <a-icon :type="specLoading ? 'loading' : 'plus'" />
                          </div>
                        </a-upload>
                      </div>
                    </span>

                    <span slot="price" slot-scope="text, record">
                      <a-input-number
                        v-model="record.price"
                        :min="0"
                        :max="9999999"
                        :precision="2"
                        placeholder="请输入"
                      />
                    </span>
                    <span slot="minPrice" slot-scope="text, record">
                      <a-input-number
                        :min="0"
                        :max="9999999"
                        :precision="2"
                        v-model="record.minPrice"
                        placeholder="请输入"
                      />
                    </span>
                    <span slot="costPrice" slot-scope="text, record">
                      <a-input-number
                        :min="0"
                        :max="9999999"
                        :precision="2"
                        v-model="record.costPrice"
                        placeholder="请输入"
                      />
                    </span>
                    <span slot="commissionRatio" slot-scope="text, record">
                      <a-input-number
                        :min="0"
                        :max="9999999"
                        :precision="2"
                        :step="0.1"
                        v-model="record.commissionRatio"
                        placeholder="请输入"
                      />
                    </span>
                    <span slot="kucun" slot-scope="text, record">
                      <a-input-number
                        v-model="record.stock"
                        :min="0"
                        :max="9999999"
                        :precision="0"
                        placeholder="请输入"
                      />
                    </span>
                    <span slot="specname" slot-scope="text, record">
                      <a-input v-model="record.name" placeholder="请输入" style="width: 90px" />
                    </span>
                    <span slot="specAction" slot-scope="text, record, index">
                      <a href="#" @click="deleteSpecData(index)">删除</a>
                    </span>
                  </a-table>
                  <a-button style="margin: 0 calc(50% - 40px); width: 80px" type="primary" @click="addSpecData"
                    >新增</a-button
                  >
                </div>
              </a-form-item>
              <a-form-item label="参数信息" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <div></div>
                <div>
                  <a-table
                    :columns="paramsColumns"
                    :data-source="paramsNameList"
                    :pagination="false"
                    rowKey="id"
                    bordered
                  >
                    <span slot="paramsValue" slot-scope="text, record, index">
                      <a-input type="textarea" v-model="record.paramsValue" placeholder="参数值" />
                    </span>
                    <span slot="paramsIndex" slot-scope="text, record, index">
                      <a-input-number v-model="record.sort" placeholder="排序值"></a-input-number>
                    </span>
                    <span slot="action" slot-scope="text, record, index">
                      <a @click="deleteParamsIndex(index)">删除</a>
                    </span>
                  </a-table>
                </div>
              </a-form-item>
              <a-form-item label="服务保障" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <a-select mode="multiple" style="width: 100%" v-model="selectServiceIds">
                  <a-select-option
                    v-for="(item, index) in goodsSecurityServices"
                    :key="item.id"
                    :value="item.id"
                    :label="item.name"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="配送信息" :labelCol="labelCol" :wrapperCol="wrapperCol">
                <div></div>
                <div>
                  <a-table
                    :columns="sendColumns"
                    :data-source="goodsSendGoods"
                    :pagination="false"
                    rowKey="id"
                    bordered
                  >
                    <span slot="sendContent" slot-scope="text, record, index">
                      <a-input
                        type="textarea"
                        v-model="record.content"
                        style="height: 50px; width: 200px"
                        placeholder="配送信息"
                      />
                    </span>
                    <span slot="action" slot-scope="text, record, index">
                      <a @click="deleteSendIndex(index)">删除</a>
                    </span>
                  </a-table>
                  <a-button style="margin: 0 calc(50% - 40px); width: 80px" type="primary" @click="addSendGoods"
                    >新增</a-button
                  >
                </div>
              </a-form-item>
              <!--     是否开发票         -->
              <!--              <a-form-item label="是否能开发票" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
              <!--                <a-radio-group name="radioGroup" v-model="isInvoice" default-value="1">-->
              <!--                  <a-radio value="2">是</a-radio>-->
              <!--                  <a-radio value="1">否</a-radio>-->
              <!--                </a-radio-group>-->
              <!--              </a-form-item>-->

              <!--     运费类型  -->
              <!--              <a-form-item label="运费类型" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
              <!--                <a-radio-group name="radioGroup" v-model="postageType" default-value="1">-->
              <!--                  <a-radio value="1">默认</a-radio>-->
              <!--                  <a-radio value="2">免费</a-radio>-->
              <!--                  <a-radio value="3">使用运费模板</a-radio>-->
              <!--                </a-radio-group>-->
              <!--              </a-form-item>-->
              <!-- 运费模板 -->
              <!--              <a-form-item label="运费模板" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
              <!--                <a-select-->
              <!--                  allow-clear-->
              <!--                  placeholder="请选择"-->
              <!--                  v-decorator="['mallFreightId', { rules: [{ required: true, message: '请选择运费模板！' }] }]"-->
              <!--                >-->
              <!--                  <a-select-option v-for="(item, index) in templateList" :key="index" :value="item.id">-->
              <!--                    {{ item.name }}-->
              <!--                  </a-select-option>-->
              <!--                </a-select>-->
              <!--                <a-button type="primary" @click="$router.push('/freightList')">添加运费模板</a-button>-->
              <!--              </a-form-item>-->
              <!-- 运费模板 -->
              <!-- <a-form-item label="运费模板" :labelCol="labelCol" :wrapperCol="wrapperCol">
                           <a-select
                            allow-clear
                            placeholder="请选择"
                            v-decorator="['template', { rules: [{ required: true, message: '请选择!' }] }]"
                           >
                            <a-select-option v-for="(item, index) in templateList" :key="index" :value="item.status">
                              {{ item.name }}
                            </a-select-option>
                          </a-select>
                          <a-button type="primary">添加运费模板</a-button>
                        </a-form-item> -->
              <!-- 排序 -->
              <!-- <a-form-item label="排序" :labelCol="labelCol" :wrapperCol="wrapperCol">
                          <a-input-number
                            :min="1"
                            v-model="queryParam.sort"
                          />
                        </a-form-item> -->
              <!-- 商品规格 -->
              <!-- <a-form-item label="商品规格" :labelCol="labelCol" :wrapperCol="wrapperCol">
                          <a-radio-group name="radioGroup" v-model="queryParam.shangpinguige" default-value="1">
                            <a-radio value="1">上架</a-radio>
                            <a-radio value="2">下架</a-radio>
                          </a-radio-group>
                        </a-form-item> -->
              <!-- 赠送优惠券 -->
              <!-- <a-form-item label="赠送优惠券" :labelCol="labelCol" :wrapperCol="wrapperCol">
                          <a-button type="primary">添加优惠券</a-button>
                          <br><br>
                          
                        </a-form-item> -->
              <a-button
                style="border-radius: 3px"
                type="primary"
                size="large"
                @click="
                  () => {
                    this.activeKey = '2'
                  }
                "
                >下一步</a-button
              >
            </a-form>
          </div>
          <!-- 商品详情页form表单 -->
          <div v-show="activeKey == '2'">
            <a-row :gutter="24">
              <a-col :md="24" :sm="24">
                <a-form :form="form">
                  <a-form-item label="商品详情" :labelCol="labelCol_1" :wrapperCol="wrapperCol_1">
                    <antd-editor
                      style="width: 700px"
                      :uploadConfig="editorUploadConfig"
                      v-model="editorContent"
                      @onchange="changeEditor"
                      @oninit="getEditor"
                    />
                    <br />
                    <a-button
                      style="border-radius: 3px"
                      type="default"
                      size="large"
                      @click="
                        () => {
                          this.activeKey = '1'
                        }
                      "
                      >上一步</a-button
                    >
                    <a-button
                      style="border-radius: 3px; margin-left: 20px"
                      type="primary"
                      size="large"
                      :loading="confirmLoading"
                      @click="querySubmit"
                      >保存</a-button
                    >
                  </a-form-item>
                </a-form>
              </a-col>
            </a-row>
          </div>
        </div>
      </a-col>
    </a-row>

    <choose-user ref="chooseUser" />
  </div>
</template>
<script>
import { queryAllParamsNameList } from '@/api/modular/mallLiving/paramsName/paramsName'
import { serviceList } from '@/api/modular/mallLiving/securityServices'
import { findCategoryListByStore } from '@/api/modular/mallLiving/commodity/commodityClass' //查询分类及二级分类
import { AntdEditor } from '@/components'

import { commodityEdit, commodityDetail, getVideoCoverImage } from '@/api/modular/mallLiving/commodity/commodityList'
import { mallFreightPage } from '@/api/modular/mallLiving/orderAdmin'
import chooseUser from './chooseUser.vue'
import { uploadFile } from '@/api/modular/mallLiving/uploadFile'
import { sortFiles } from '@/utils/util'
import { areaPage } from '@/api/modular/mallLiving/area'

const columns = [
  {
    title: '规格',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: {
      customRender: 'specname',
    },
  },
  {
    title: '图片',
    dataIndex: 'image',
    key: 'image',
    scopedSlots: {
      customRender: 'tupian',
    },
  },
  {
    title: '价格',
    dataIndex: 'price',
    key: 'price',
    scopedSlots: {
      customRender: 'price',
    },
  },
  {
    title: '业务员可调整的最低价',
    dataIndex: 'minPrice',
    key: 'minPrice',
    scopedSlots: {
      customRender: 'minPrice',
    },
  },
  {
    title: '成本价',
    dataIndex: 'costPrice"',
    key: 'costPrice',
    scopedSlots: {
      customRender: 'costPrice',
    },
  },
  {
    title: '业务员卖出平台的商品，利润拿出去和代理商分的比例',
    dataIndex: 'commissionRatio',
    key: 'commissionRatio',
    scopedSlots: {
      customRender: 'commissionRatio',
    },
  },
  {
    title: '库存',
    key: 'stock',
    dataIndex: 'stock',
    scopedSlots: {
      customRender: 'kucun',
    },
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: {
      customRender: 'specAction',
    },
  },
]

export default {
  data() {
    return {
      paramsColumns: [
        {
          title: '参数名',
          dataIndex: 'name',
        },
        {
          title: '参数值',
          dataIndex: 'paramsValue',
          scopedSlots: {
            customRender: 'paramsValue',
          },
        },
        {
          title: '操作',
          key: 'action',
          dataIndex: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      serviceColumn: [
        {
          title: '服务名称',
          dataIndex: 'name',
        },
        {
          title: '服务描述',
          dataIndex: 'conent',
          scopedSlots: {
            customRender: 'serviceContent',
          },
        },
        {
          title: '排序值',
          dataIndex: 'sort',
          scopedSlots: {
            customRender: 'serviceIndex',
          },
        },
        {
          title: '图标',
          dataIndex: 'logo',
          scopedSlots: {
            customRender: 'serviceLogo',
          },
        },
        {
          title: '操作',
          key: 'action',
          dataIndex: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      sendColumns: [
        {
          title: '内容',
          dataIndex: 'content',
          scopedSlots: {
            customRender: 'sendContent',
          },
        },
        {
          title: '操作',
          key: 'action',
          dataIndex: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      columns,
      activeKey: '1', //tab页
      //封面图
      picture: '',
      pictureLoading: false,
      details: {},
      //轮播图
      lunbotu: '',
      lunbotuLoading: false,
      //主图视频
      video: '',
      videoLoading: false,
      //规格图片
      spec: '',
      specLoading: false,
      //富文本
      speType: 1,
      editorUploadConfig: {
        method: 'http',
        callback: this.editorUploadImage,
      },
      selectServiceIds: [],
      fileList: [],
      editor: '',
      editorContent: '',
      editorContentText: '',
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 2,
        },
      },
      uploadOption: null,
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 13,
        },
      },
      labelCol_1: {
        xs: {
          span: 24,
        },
        sm: {
          span: 2,
        },
      },
      wrapperCol_1: {
        xs: {
          span: 24,
        },
        sm: {
          span: 20,
        },
      },
      areas: [],
      templateList: [],
      //表单请求参数
      data: [],
      isInvoice: '1', // 是否开发票
      postageType: '3', // 运费类型
      form: this.$form.createForm(this),
      classList: [],
      previewVisible1: false,
      previewImage: '',
      confirmLoading: false,
      specIndex: 0,
      paramsNameList: [],
      goodsSecurityServices: [],
      serviceIndex: 0,
      goodsSendGoods: [],
      videoSrc: '',
      videoCoverImage: '',
      serviceList: [],
    }
  },
  components: {
    AntdEditor,
    chooseUser,
  },

  mounted() {
    this.getAreas()
    this.getCommodityClassList()
    this.getCommodityDetail()
  },
  methods: {
    getAreas() {
      areaPage({
        queryParam: {},
        page: {},
      }).then((res) => {
        console.info('areas', res)
        if (res.success && res.data) {
          this.areas = res.data.rows
        }
      })
    },
    setUser(user) {
      console.log('user', user, this.form)

      this.form.setFieldsValue({ storeName: user.name })
      this.form.setFieldsValue({ storeId: user.id })
    },
    changeSelectCategory(e) {
      let that = this
      queryAllParamsNameList({
        id: e,
      }).then((res) => {
        that.paramsNameList = res.data
        that.paramsNameList.forEach((item, index) => {
          item.paramsValue = ''
          item.paramsIndex = index
          item.paramId = ''
        })
        that.details.goodsParams.forEach((item) => {
          that.paramsNameList.forEach((citem) => {
            if (citem.name == item.paramName) {
              citem.paramsValue = item.paramValue
              citem.paramId = item.id
              citem.sort = item.sort
            }
          })
        })
        that.paramsNameList = JSON.parse(JSON.stringify(that.paramsNameList))
      })
    },
    getServiceList() {
      let that = this
      serviceList().then((res) => {
        that.goodsSecurityServices = res.data
        that.goodsSecurityServices.forEach((item, index) => {
          item.sort = index
          item.serviceId = ''
        })
        that.goodsSecurityServices.forEach((item) => {
          that.details.goodsSecurityServices
            ? that.details.goodsSecurityServices.forEach((citem) => {
                if (item.id == citem.securityId) {
                  item.serviceId = citem.id
                }
              })
            : []
        })
        that.goodsSecurityServices = JSON.parse(JSON.stringify(that.goodsSecurityServices))
        console.log(that.goodsSecurityServices)
      })
    },
    addSendGoods() {
      this.goodsSendGoods.push({
        sort: this.goodsSendGoods.length,
        content: '',
      })
    },
    formatFileName() {
      var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        a = t.length,
        n = ''

      for (var i = 0; i < 10; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n + new Date().getTime()
    },
    deleteSendIndex(index) {
      this.goodsSendGoods.splice(index, 1)
    },
    deleteServiceIndex(index) {
      this.goodsSecurityServices.splice(index, 1)
    },
    deleteParamsIndex(index) {
      this.paramsNameList.splice(index, 1)
    },
    chooseService(index) {
      this.serviceIndex = index
    },
    getCommodityDetail() {
      let that = this
      commodityDetail({
        id: that.$route.query.id,
      }).then((res) => {
        // 如果封面图存在
        if (res.data.rotationImages) {
          var list = res.data.rotationImages.split(',')
          list.forEach((item, index) => {
            var suffix = item.split('.')[item.split('.').length - 1]
            if (suffix == 'mp4' || suffix == 'ogg' || suffix == 'webm') {
              that.videoSrc = item
            } else {
              that.fileList.push({
                url: item,
                uid: index,
                name: item.split('/')[item.split('/').length - 1],
                status: 'done',
              })
            }
          })
        }

        that.picture = res.data.coverImage
        that.speType = res.data.speType
        this.isInvoice = res.data.isInvoice ? String(res.data.isInvoice) : '1'
        this.postageType = res.data.postageType ? String(res.data.postageType) : '1'

        if (res.data.specificationsList) {
          res.data.specificationsList.forEach((item) => {
            that.data.push({
              name: item.name,
              price: item.price,
              minPrice: item.minPrice,
              costPrice: item.costPrice,
              commissionRatio: item.commissionRatio,
              stock: item.stock,
              image: item.image,
              id: item.id,
            })
          })
        }
        that.details = res.data
        that.videoCoverImage = res.data.videoCoverImage
        let slist = res.data.goodsSendGoods ? res.data.goodsSendGoods : []
        slist.forEach((item) => {
          that.goodsSendGoods.push({
            content: item.content,
            id: item.id,
            sort: that.goodsSendGoods.length,
          })
        })
        ;(that.serviceList = res.data.goodsSecurityServices ? res.data.goodsSecurityServices : []),
          (that.selectServiceIds = [])
        that.serviceList.forEach((item) => {
          that.selectServiceIds.push(item.securityId)
        })
        that.getServiceList()
        that.changeSelectCategory(res.data.goodsCategoryId)
        console.log(res.data.storeName)
        this.$nextTick(function () {
          that.form.setFieldsValue({
            storeName: res.data.storeName,
            storeId: res.data.storeId,
            blacklistAreaIds: res.data.blacklistAreaIds,
            name: res.data.name,
            marketPrice: res.data.marketPrice,
            minPrice: res.data.minPrice,
            costPrice: res.data.costPrice,
            commissionRatio: res.data.commissionRatio,
            keywords: res.data.keywords,
            coverImage: res.data.coverImage,
            mallFreightId: res.data.mallFreightId || '',
            goodsCategoryId: res.data.goodsCategoryId,
            tempList: {
              tempList: ['tempList'],
            }, //随便添加一个临时数据用来占位
          })
        })

        if (res.data.returnGoodsPolicy) {
          that.form.setFieldsValue({
            returnGoodsPolicy: res.data.returnGoodsPolicy,
          })
        }
        that.editor.txt.html(res.data.detail)
        that.editorContent = res.data.detail
      })
    },
    addSpecData() {
      this.data.push({
        stock: '',
        name: '',
        image: '',
        price: '',
        minPrice: '',
        costPrice: '',
        commissionRatio: '',
        id: null,
      })
    },
    deleteSpecData(index) {
      this.data.splice(index, 1)
    },
    //运费模板列表
    getTemplateList() {
      mallFreightPage({}).then((res) => {
        console.log('模板', res)
        if (res.success) {
          this.templateList = res.data.rows
        }
      })
    },
    // 获取商品分类及其子列表信息
    getCommodityClassList() {
      findCategoryListByStore().then((res) => {
        //处理分类数据
        this.classList = res.data
      })
    },
    tabCallback() {
      console.log(this.activeKey)
    },
    // 富文本内容变化
    changeEditor(html, ele) {
      this.editorContent = html
      this.editorContentText = ele.text()
    },
    // 富文本初始化
    getEditor(editor) {
      this.editor = editor
    },
    // 富文本
    async editorUploadImage(files, insert) {
      for (const file of sortFiles(files)) {
        const res = await uploadFile(file.name, file, {
          progress: function (p, checkpoint) {
            const percentage = Math.floor(p * 100)
          },
        })
        console.log('res', res)
        if (res.res.statusCode == 200) {
          const list = res.res.requestUrls[0].split('?')
          if (list.length > 0) {
            insert(list[0])
          }
        }
      }
    },
    customVideoRequest(filedata) {
      this.pictureLoading = true
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          var list = res.res.requestUrls[0].split('?')
          if (list.length > 0) {
            this.videoSrc = list[0]
            getVideoCoverImage({ url: this.videoSrc }).then((dataRes) => {
              if (dataRes.code == 200) {
                this.videoCoverImage = dataRes.data
              }
            })
            this.pictureLoading = false
          }
        }
      })
    },

    //自定义商品封面图上传
    customPictureRequest(filedata) {
      let that = this
      this.pictureLoading = true
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          var list = res.res.requestUrls[0].split('?')
          if (list.length > 0) {
            this.picture = list[0]
            this.pictureLoading = false
          }
        }
      })
    },
    handleChange({ fileList }) {
      if (fileList.length > 9) {
        return
      }
      this.fileList = fileList

      //如果轮播图都删除了，把tempList置空
      this.$nextTick(() => {
        if (this.form.getFieldValue('tempList') && this.form.getFieldValue('tempList').fileList.length == 0) {
          this.form.setFieldsValue({
            tempList: null,
          })
        }
      })
    },
    handleCancel() {
      this.previewVisible1 = false
    },
    handleCancel1() {
      this.previewVisible1 = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible1 = true
    },
    showFigureRequest(event) {
      let file = event.file
      uploadFile(file.name, file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          var list = res.res.requestUrls[0].split('?')
          if (list.length > 0) {
            let index = this.fileList.findIndex((item) => item.uid === file.uid)
            if (index > -1) {
              this.fileList.splice(index, 1, {
                url: list[0],
                uid: file.uid,
                name: file.name,
                status: 'done',
              })
            }
          }
        }
      })
    },
    chooseSpecIndex(index) {
      this.specIndex = index
    },
    //自定义规格图片上传
    customSpecRequest(filedata) {
      let that = this
      that.specLoading = true
      uploadFile(filedata.file.name, filedata.file, {
        progress: function (p, checkpoint) {
          var percentage = Math.floor(p * 100)
        },
      }).then((res) => {
        console.log('res', res)
        if (res.res.statusCode == 200) {
          var list = res.res.requestUrls[0].split('?')
          if (list.length > 0) {
            that.data[that.specIndex].image = list[0]
            that.specLoading = false
          }
        }
      })
    },
    //提交表单信息
    querySubmit() {
      let that = this
      this.confirmLoading = true
      if (this.speType != 1 && this.speType != 2) {
        this.$message.error('请选择商品规格类型')
        this.confirmLoading = false
        return
      }
      this.form.validateFields((errors, values) => {
        if (!errors) {
          delete values.tempList //删除临时的轮播图列表数据
          var files = ''
          if (this.fileList) {
            this.fileList.forEach((item, index) => {
              files += index < this.fileList.length - 1 ? item.url + ',' : item.url
            })
          }
          if (that.videoSrc) {
            files = that.videoSrc + ',' + files
            values.videoCoverImage = that.videoCoverImage
          } else {
            values.videoCoverImage = ''
          }
          values.isInvoice = this.isInvoice
          values.postageType = this.postageType
          values.rotationImages = files
          values.detail = this.editorContent
          values.speType = this.speType
          let serviceList = []
          this.goodsSecurityServices.forEach((item) => {
            that.selectServiceIds.forEach((citem) => {
              if (citem == item.id) {
                serviceList.push({
                  id: item.serviceId,
                  securityId: item.id,
                })
              }
            })
          })
          let sendList = []
          let clist = this.goodsSendGoods.filter((item) => {
            return item.content
          })
          clist.forEach((item) => {
            sendList.push({
              id: item.id,
              content: item.content,
            })
          })
          let paramsList = []
          let pList = this.paramsNameList.filter((item) => {
            return item.paramsValue
          })
          pList.forEach((item) => {
            paramsList.push({
              id: item.paramId,
              paramName: item.name,
              paramValue: item.paramsValue,
              sort: item.sort,
            })
          })
          values.goodsParams = paramsList
          values.goodsSecurityServices = serviceList
          values.goodsSendGoods = sendList
          if (this.speType == 1) {
            var list = this.data.filter((item) => {
              return item.name && item.image
            })
            if (list.length == 0) {
              this.$message.error('请输入商品规格')
              this.confirmLoading = false
              return
            } else {
              var specificationsList = []
              list.forEach((element) => {
                specificationsList.push({
                  name: element.name,
                  stock: element.stock,
                  price: element.price,
                  minPrice: element.minPrice,
                  costPrice: element.costPrice,
                  commissionRatio: element.commissionRatio,
                  image: element.image,
                  id: element.id,
                  delFlag: 1,
                })
              })
              values.specificationsList = that.speType == 1 ? specificationsList : []
              values.delFlag = 1
              values.coverImage = this.picture
              values.id = that.$route.query.id
              values.partitionId = that.$route.query.partitionId
              console.log(values)
              commodityEdit(values).then((res) => {
                if (res.success) {
                  this.$message.success('编辑商品成功！')
                  this.$router.push({
                    path: `/partitionitems?partitionId=${that.$route.query.partitionId}`,
                  })
                  this.confirmLoading = false
                  this.handleCancel()
                }
              })
            }
          } else {
            values.specificationsList = []
            values.delFlag = 1
            values.coverImage = this.picture
            values.id = that.$route.query.id
            values.partitionId = that.$route.query.partitionId

            console.log(values)
            commodityEdit(values)
              .then((res) => {
                if (res.success) {
                  this.$message.success('编辑商品成功！')
                  this.$router.push({
                    path: `/partitionitems?partitionId=${that.$route.query.partitionId}`,
                  })
                  this.confirmLoading = false
                  this.handleCancel()
                }
              })
              .finally(() => {
                setTimeout(() => {
                  this.confirmLoading = false
                }, 3000)
              })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
// @import url(); 引入公共css
.clearfix {
  width: 100px;
  height: 100px;
}

.prcture {
  width: 200px !important;
  position: relative;
  z-index: 1000;
}

.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 10px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}

.flex {
  display: flex;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
